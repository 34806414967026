<script>
export default {
    name: 'BaseSpinner',
    props: {
        size: {
            type: String,
            default: 'medium', // small, medium, large
        },
    },
}
</script>

<template>
    <div class="spinner-container">
        <div
            class="spinner"
            :class="size"
        />
    </div>
</template>


<style lang="scss" scoped>
@use '@design' as *;

.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.spinner {
    width: var(--spinner-size);
    height: var(--spinner-size);
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: $c-highlight;
    border-radius: 50%;
    animation: spin 1s linear infinite;

    &.small {
        --spinner-size: 20px;
    }

    &.medium {
        --spinner-size: 40px;
    }

    &.large {
        --spinner-size: 60px;
    }
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}
</style>
