<script>
import PickerLayout from './picker-layout'
import ArtistTile from './../../../../components/artist/artist-tile.vue'

export default {
    components: {  PickerLayout, ArtistTile },
    props: {
        artists: {
            type: Array,
            required: true,
        },
        selectedArtists: {
            type: Array,
            required: true,
        },
        isLoading: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            searchQuery: '',
        }
    },
}
</script>

<template>
    <PickerLayout
        :is-loading="isLoading"
        :has-results="artists.length > 0"
    >
        <div slot="header">
            <h1 class="h2">Pick 3 or more Artists</h1>
            <div class="separator"/>
            <p class="subtitle">Choose your favorite artists for more personalized recommendations.</p>
            <BaseInput
                v-model="searchQuery"
                placeholder="Search"
                :disabled="isLoading"
                @input="$emit('onSearch', searchQuery)"
            />
        </div>
        <div slot="picker">
            <div class="items-grid">
                <div
                    v-for="artist of artists"
                    :key="'artist-'+artist.id"
                    @click="$emit('onPick', artist)"
                >
                    <ArtistTile
                        :artist="artist"
                        is-on-select-mode
                        :is-selected="!!selectedArtists.find((item) => item.id === artist.id)"
                    />
                </div>
            </div>
        </div>
        <div
            slot="actions"
            class="actions"
        >
            <BaseButton
                button-type="secondary"
                @click="$emit('onBack')"
            >Select Genres</BaseButton>
            <BaseButton
                button-type="highlight"
                @click="$emit('onNext')"
            >{{ selectedArtists.length > 0 ? 'Done' : 'Skip' }}</BaseButton>
        </div>
    </PickerLayout>
</template>

<style lang="scss" scoped>
@use '@design' as *;

.items-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    gap: $s-xl;
}

.actions {
    display: flex;
    justify-content: space-between;
    gap: $s-l;
    width: 100%;
}
</style>
