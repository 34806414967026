<script>
import FullscreenLayout from '../../layouts/fullscreen.vue'

export default {
    components: { FullscreenLayout },

    computed: {
        isStandalone() {
            return this.$store.state.premium.isStandalone
        },
        isLoggedIn() {
            return this.$store.getters['auth/loggedIn']
        },
    },

    mounted() {
        this.$store.dispatch('premium/toggleStandaloneMode', this.$route.path.includes('offer'))
        if (!this.isLoggedIn && !this.$route.path.includes('offer'))
            this.$router.push({ path: '/offer' + this.$route.path })
    },
}
</script>

<template>
    <FullscreenLayout>
        <div :class="$style.wrapper">
            <div :class="$style.background" />
            <div :class="$style.main">
                <router-view
                    :key="$route.path"
                    :class="$style.childView"
                />
            </div>
        </div>
    </FullscreenLayout>
</template>

<style lang="scss" module>
@use '@design' as *;

.wrapper {
    position: relative;
    z-index: 10;
    width: 100%;
    height: 100vh;
    overflow-x: hidden;
}

.background {
    @include fill;

    position: fixed;
    background: $c-grey-woodsmoke;
    background-image: url('/images/subscrpiption-background.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}

.main {
    position: relative;
    width: 1024px;
    max-width: 100%;
    min-height: 100vh;
    margin: 0 auto;
    text-align: center;
}

.childView {
    padding-bottom: $s-h;
}

@include tablet {
    .main {
        padding: $s-h 0;
        text-align: left;
    }
}
</style>
