<script>
import DataLayer from '@services/data-layer/index'
import GenresPicker from './components/genres-picker'
import ArtistsPicker from './components/artists-picker'
import { uniqueFromCombinedArrays } from '@utils/arrays'
import { debounce } from './../../../utils/performance'
import analyticsService from './../../../services/analytics-service'

const ARTISTS_LIMIT_PER_GENRE = 5
const DEBOUNCE_DELAY = 300

export default {
    page() {
        const title = 'Onboarding'
        const description = 'Onboarding'
        return {
            title,
            meta: [
                { name: 'title', vmid: 'title', content: title },
                { name: 'description', vmid: 'description', content: description },

                { property: 'og:title', vmid: 'og:title', content: title },
                { property: 'og:description', vmid: 'og:description', content: description },

                { property: 'twitter:title', vmid: 'twitter:title', content: title },
                { property: 'twitter:description', vmid: 'twitter:description', content: description },
            ],
        }
    },
    components: { GenresPicker, ArtistsPicker },
    data() {
        return {
            currentlyPicking: 'genres',
            genres: [],
            artists: [],
            selectedGenres: [],
            selectedArtists: [],
            isLoading: false
        }
    },

    computed: {
        membersId() {
            return this.$store.state.auth.currentUser.members_id.id
        }
    },
    async mounted() {
        await this.getGenres()
    },
    methods: {
        async getGenres() {
            this.isLoading = true
            const res = await DataLayer.genres.getAll()
            this.genres = res.body || []
            this.isLoading = false
        },

        async getArtistsByGenres(genres) {
            this.isLoading = true
            const calls = this.selectedGenres.map((genre) => DataLayer.artists.getRandomByGenre(genre.name, ARTISTS_LIMIT_PER_GENRE))
            const responses = await Promise.all(calls)
            this.artists = uniqueFromCombinedArrays(responses.map((res) => res.body || []), 'id')
            this.isLoading = false
        },

        async getArtistsByName(query) {
            if(!query) {
                this.getArtistsByGenres(this.selectedGenres)
                return
            }
            this.isLoading = true
            const res = await DataLayer.artists.getByName(query)
            this.artists = res.body || []
            this.isLoading = false
        },

        async goToArtistsPicking() {
            this.currentlyPicking = 'artists'
            if(this.artists.length > 0) return
            await this.getArtistsByGenres(this.selectedGenres)
        },

        goToGenresPicking() {
            this.currentlyPicking = 'genres'
        },

        async requestAllArtistsFollow() {
            const responses = await Promise.all(this.selectedArtists.map((artist) => DataLayer.favorites.add({ type: 'artists', membersId: this.membersId, id: artist.id })))
            return responses
        },

        async requestAllGenresFollow() {
            const responses = await Promise.all(this.selectedGenres.map((genre) => DataLayer.favorites.add({ type: 'genres', membersId: this.membersId, id: genre.id })))
            return responses
        },

        async completePicking() {
            await Promise.allSettled([this.requestAllArtistsFollow(), this.requestAllGenresFollow()])
            analyticsService.onboardingGenresFollowedCount(this.selectedGenres.length)
            window.location.href = '/'
        },

        onGenrePick(genre) {
            if(this.selectedGenres.find((item) => item.id === genre.id)) {
                this.selectedGenres = [...this.selectedGenres.filter((item) => item.id !== genre.id)]
            }
            else this.selectedGenres.push({ ...genre })

            this.artists = []
        },

        onArtistPick(artist) {
            if(this.selectedArtists.find((item) => item.id === artist.id)) {
                this.selectedArtists = [...this.selectedArtists.filter((item) => item.id !== artist.id)]
            }
            else this.selectedArtists.push({ ...artist })
        },

        onSearchDebounce: debounce(function(query) {
            this.getArtistsByName(query)
        }, DEBOUNCE_DELAY)
    }
}
</script>

<template>
    <GenresPicker
        v-if="currentlyPicking === 'genres'"
        :genres="genres"
        :selected-genres="selectedGenres"
        :is-loading="isLoading"
        @onPick="onGenrePick"
        @onNext="goToArtistsPicking"
    />
    <ArtistsPicker
        v-else-if="currentlyPicking === 'artists'"
        :artists="artists"
        :selected-artists="selectedArtists"
        :is-loading="isLoading"
        @onPick="onArtistPick"
        @onBack="goToGenresPicking"
        @onNext="completePicking"
        @onSearch="onSearchDebounce"
    />
</template>

<style lang="scss" scoped>
@use '@design' as *;
@use 'sass:color';

</style>
