export function debounce(func, delay) {
    let timeout = null;

    return function(...args) {
        if(timeout) {
            clearTimeout(timeout);
            timeout = null;
        }

        timeout = setTimeout(() => func.apply(this, args), delay);
    };
}
