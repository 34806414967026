<script>
import DataLayer from './../../services/data-layer/index'
import Layout from './../layouts/main'
// import GenreGridBlock from './../../components/genre-grid-block'

export default {
    page() {
        const title = 'Your Music - Genres'
        const description = 'Your Music - Genres'
        return {
            title,
            meta: [
                { name: 'title', vmid: 'title', content: title },
                { name: 'description', vmid: 'description', content: description },

                { property: 'og:title', vmid: 'og:title', content: title },
                { property: 'og:description', vmid: 'og:description', content: description },

                { property: 'twitter:title', vmid: 'twitter:title', content: title },
                { property: 'twitter:description', vmid: 'twitter:description', content: description },
            ],
        }
    },
    components: { Layout },
    data() {
        return {
            isLoading: false,
            genres: [],
            favoritesService: DataLayer.favorites
        }
    },
    computed: {
        membersId() {
            return this.$store.state.auth.currentUser.members_id.id
        }
    },
    async mounted() {
        await this.getFavorites()
    },
    methods: {
        async getFavorites() {
            this.isLoading = true
            const res = await this.favoritesService.getAll({type: 'genres', membersId: this.membersId })
            const genres = res.body?.map((item) => item.genres_id) || []
            if (genres && genres.length) this.genres = genres
            this.isLoading = false
        },
        async unfollowGenre(genreId) {
            await this.favoritesService.remove({type: 'genres', membersId: this.membersId, id: genreId })
            this.genres = this.genres.filter((genre) => genre.id !== genreId)
        },
    },
}
</script>

<template>
    <Layout>
        <BaseHero
            :class="$style.mainHeading"
            text="Favorite Genres"
        />
        <BaseContent>
            <BaseSection padded>
                <BaseSpinner v-if="isLoading"/>
                <div
                    v-else
                    class="items"
                >
                    <div
                        v-for="genre of genres"
                        :key="'fav-genre-'+genre.id"
                        class="item"
                    >
                        <p>{{ genre.id }}</p>
                        <h6 @click="unfollowGenre(genre.id)">remove</h6>
                    </div>
                    <div v-if="genres.length === 0">No items</div>
                </div>
            </BaseSection>
        </BaseContent>
    </Layout>
</template>

<style lang="scss" module>
@use '@design' as *;

.mainHeading h1 {
    margin-top: 0;
}

.items {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}

.item {
    padding: $s-m;
    border-radius: 12px;
    background-color: $c-black;
}
</style>
