<script>
import GenreGridTile from '@components/genre-grid-tile'

export default {
    components: {
        GenreGridTile,
    },
    props: {
        genres: {
            type: Array,
            default: () => [],
        },
        scroll: {
            type: Boolean,
            default: false,
        },
        scrollMobileOnly: {
            type: Boolean,
            default: false,
        },
        padded: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<template>
    <div
        :class="[
            $style.block,
            scroll && $style.scroll,
            scrollMobileOnly && $style.scrollMobileOnly,
            padded && $style.blockPadded,
        ]"
    >
        <GenreGridTile
            v-for="(genre, index) in genres"
            :key="genre.name + index + 'list'"
            :genre="genre"
            :index="index"
            :class="$style.tile"
        />
    </div>
</template>

<style lang="scss" module>
@import '@design';

.block {
    display: grid;
    gap: $s-xl;

    &Padded {
        @include sidePadding;
    }
}

@include tablet {
    .block {
        grid-template-columns: repeat(2, 1fr);
    }
}

@include desktop {
    .block {
        grid-template-columns: repeat(3, 1fr);
    }
}

@include widescreen {
    .block {
        grid-template-columns: repeat(4, 1fr);
    }
}
</style>
