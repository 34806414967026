<script>
import svgSprite from './components/svg-sprite'
import NotificationDialog from './components/notification-dialog'
import socialImage from '@assets/images/frisky-social-share-image.png'
import AppInitializer from './services/app-initializer'
import Logo from './components/logo'

export default {
    components: {
        svgSprite,
        NotificationDialog,
        Logo,
    },

    page: {
        // All subcomponent titles will be injected into this template.
        titleTemplate(title) {
            title = typeof title === 'function' ? title(this.$store) : title
            return title ? `FRISKY | ${title}` : 'FRISKY'
        },
        meta: [
            { property: 'og:title', vmid: 'og:title', content: `FRISKY` },
            { property: 'og:type', vmid: 'og:type', content: 'website' },
            { property: 'og:url', vmid: 'og:url', content: `${window.location.href}` },
            { property: 'og:image', vmid: 'og:image', content: socialImage },
            { property: 'fb:app_id', vmid: 'fb:app_id', content: '126928420652882' },

            { property: 'twitter:card', vmid: 'twitter:card', content: 'sumary' },
            { property: 'twitter:url', vmid: 'twitter:url', content: `${window.location.href}` },
            { property: 'twitter:site', vmid: 'twitter:site', content: '@friskyradio' },
            { property: 'twitter:creator', vmid: 'twitter:creator', content: '@friskyradio' },
            { property: 'twitter:image', vmid: 'twitter:image', content: socialImage },
        ],
    },

    data() {
        return {
            isLoading: false,
            socialImage,
            appInitializer: null,
        }
    },

    computed: {
        loggedIn() {
            return this.$store.getters['auth/loggedIn']
        },
    },

    watch: {
        loggedIn: {
            handler() {
                this.updateFavorites()
            },
            immediate: true,
        },
        routeKey(to, from) {
            this.updateFavorites()
        },
    },

    async mounted() {
        this.isLoading = true
        await this.initializeApp()
        this.isLoading = false
        this.initKeyControls()
    },

    beforeDestroy() {
        window.removeEventListener('keydown', this.$keyControlsService.reactToKey)
        if (this.appInitializer) this.appInitializer.destroy()
    },

    methods: {
        updateFavorites() {
            this.loggedIn && this.$dataLayer.favMixes.init()
            this.loggedIn && this.$dataLayer.favArtists.init()
            this.loggedIn && this.$dataLayer.favShows.init()
        },

        initKeyControls() {
            window.addEventListener('keydown', this.$keyControlsService.reactToKey)
        },

        async initializeApp() {
            this.appInitializer = new AppInitializer(
                this.$store,
                this.$router,
                this.$dataLayer,
                this.$m
            )
            await this.appInitializer.initialize()
        },
    },
}
</script>

<template>
    <div id="app">
        <svg-sprite />
        <div
            v-if="isLoading"
            class="app-content-loader"
        >
            <Logo size="large" />
        </div>
        <router-view v-else />
        <NotificationDialog v-if="$store.state.appearance.isNotificationOpen" />
    </div>
</template>

<style lang="scss">
// Allow element/type selectors, because this is global CSS.
// stylelint-disable selector-max-type, selector-class-pattern
@use '@design' as *;

@import '@design/animations';
@import '@design/normalize';
@import '@design/utilities';

@import '~nprogress/nprogress.css';
@import url('https://use.typekit.net/gom4uqy.css');

@include init;

*,
*::before,
*::after {
    box-sizing: border-box;
}

::-webkit-scrollbar {
    width: $size-scrollbar-width;
}

::-webkit-scrollbar-track {
    opacity: 0;
}

::-webkit-scrollbar-thumb {
    min-height: $s-xxl;
    background-color: $c-tuna;
    background-clip: padding-box;
    border: 4px solid transparent;
    border-radius: 16px;
}

html {
    scroll-behavior: smooth;
}

body {
    width: 100vw;
    height: 100vh;
    background: $c-body-bg;

    &:has([is-nav-open="true"]) {
        overflow: hidden;
    }
}

option {
    color: $c-white;
    background: $c-grey-woodsmoke;
}

@include desktop {
    body {
        &:has([is-nav-open="true"]) {
            overflow: unset;
        }
    }
}

.app-content-loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.app-loader-logo {
    animation: fade 1.7s infinite alternate ease-in-out;
}

// ===
// Vendor
// ===

#nprogress .bar {
    height: 5px;
    background: $c-link-text;
    will-change: transform;
}

#nprogress .peg {
    box-shadow: 0 0 10px $c-link-text, 0 0 5px $c-link-text;
}


</style>
