<script>
import GenreGridTile from './../../../../components/genre-grid-tile.vue'
import PickerLayout from './picker-layout'

export default {
    components: {  PickerLayout, GenreGridTile },
    props: {
        genres: {
            type: Array,
            required: true,
        },
        selectedGenres: {
            type: Array,
            required: true,
        },
        isLoading: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            MINIMUM_GENRES: 3,
        }
    },
    computed: {
        hasResults() {
            return this.genres.length > 0
        }
    }
}
</script>

<template>
    <PickerLayout
        :is-loading="isLoading"
        :has-results="hasResults"
    >
        <div slot="header">
            <h1 class="h2">Pick 3 or more Genres</h1>
            <div class="separator"/>
            <p class="subtitle">Choose your favorite genres for more personalized recommendations.</p>
        </div>
        <div slot="picker">
            <div class="items-grid">
                <div
                    v-for="genre of genres"
                    :key="'genre-'+genre.id"
                    @click="$emit('onPick', genre)"
                >
                    <GenreGridTile
                        :genre="genre"
                        is-on-select-mode
                        :is-selected="!!selectedGenres.find((item) => item.id === genre.id)"
                    />
                </div>
            </div>
        </div>
        <div
            slot="actions"
            class="actions"
        >
            <BaseButton
                :disabled="selectedGenres.length < MINIMUM_GENRES"
                button-type="highlight"
                @click="$emit('onNext')"
            >Next</BaseButton>
        </div>
    </PickerLayout>
</template>

<style lang="scss" scoped>
@use '@design' as *;

.items-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: $s-xl;
    width: 100%;
}

.actions {
    display: flex;
    justify-content: flex-end;
    gap: $s-l;
    width: 100%;
}
</style>
