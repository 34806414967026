<script>
import Layout from '@layouts/main'
import GenreGridBlock from '@components/genre-grid-block'
import DataLayer from '@services/data-layer/index'

export default {
    page() {
        const title = 'Genres & Moods'
        const description = 'Genres & Moods'
        return {
            title,
            meta: [
                { name: 'title', vmid: 'title', content: title },
                { name: 'description', vmid: 'description', content: description },

                { property: 'og:title', vmid: 'og:title', content: title },
                { property: 'og:description', vmid: 'og:description', content: description },

                { property: 'twitter:title', vmid: 'twitter:title', content: title },
                { property: 'twitter:description', vmid: 'twitter:description', content: description },
            ],
        }
    },
    components: { Layout, GenreGridBlock },
    data() {
        return {
            genres: [],
        }
    },
    mounted() {
        this.getGenres()
    },
    methods: {
        async getGenres() {
            const res = await DataLayer.genres.getAll()
            this.genres = res.body || []
        },
    }
}
</script>

<template>
    <Layout>
        <div :class="$style.mainHeading">Genres & Moods</div>
        <BaseContent>
            <BaseSection padded>
                <GenreGridBlock :genres="genres" />
            </BaseSection>
        </BaseContent>
    </Layout>
</template>

<style lang="scss" module>
@import '@design';

.mainHeading {
    @include h3;

    margin-top: $s-h;
    margin-bottom: $s-xxl;
    letter-spacing: 0.25px;
    &::after {
        display: block;
        min-height: 1px;
        margin-top: $s-xl;
        content: '';
        background: $c-dusty-gray;
        opacity: 0.1;
    }

    @include desktop {
        @include container;
        @include h1;

        margin-bottom: $s-xxl;
    }

    @include sidePadding;
}
</style>
