<script>
import DataLayer from '@services/data-layer/index'

import Layout from '@layouts/main'
import MixGridBlock from '@components/mix-grid-block'
import MixTableBlock from '@components/mix-table-block.v2'
import ArtistBlock from '@components/artist/artist-block'

export default {
    page() {
        const title = this.genreTitle ? `${this.genreTitle}` : `Genre`
        const description = this.genreTitle ? `${this.genreTitle}` : `Genre`
        return {
            title,
            meta: [
                { name: 'title', vmid: 'title', content: title },
                { name: 'description', vmid: 'description', content: description },

                { property: 'og:title', vmid: 'og:title', content: title },
                { property: 'og:description', vmid: 'og:description', content: description },

                { property: 'twitter:title', vmid: 'twitter:title', content: title },
                { property: 'twitter:description', vmid: 'twitter:description', content: description },
            ],
        }
    },
    components: { Layout, MixGridBlock, MixTableBlock, ArtistBlock },
    data() {
        return {
            allPopularMixes: Array.from(Array(5), (e, i) => ({
                id: i,
                genre: [],
            })),
            latestMixes: Array.from(Array(10), (e, i) => ({
                id: i,
                genre: [],
            })),
            artists: Array.from(Array(10), (e, i) => ({
                id: i,
            })),
            INITIAL_POPULAR_MIXES: 5,
            visiblePopularMixes: 5,
            INITIAL_LATEST_MIXES: 12,
            visibleLatestMixes: 12,
            genre: null
        }
    },
    computed: {
        popularMixes() {
            return this.allPopularMixes.slice(0, this.visiblePopularMixes)
        },
        genreTitle() {
            if (this.$router.currentRoute.params.id) {
                const splitGenre = this.$router.currentRoute.params.id
                    .split('_')
                    .map(el => el.charAt(0).toUpperCase() + el.slice(1))
                return splitGenre.join(' ')
            } else return ''
        },
    },
    mounted() {
        const genreTitle = this.genreTitle
        Promise.all([
            DataLayer.mixes.getPopularByGenre(genreTitle),
            DataLayer.mixes.getLatestByGenre(genreTitle),
            DataLayer.artists.getRandomByGenre(genreTitle),
            DataLayer.genres.getAll(),
        ]).then(([popularMixes, latestMixes, artists, genres]) => {
            this.allPopularMixes = popularMixes.body
            this.latestMixes = latestMixes.body
            this.artists = artists.body
            this.genre = genres.body.find(genre => genre.name === genreTitle)
        })
    },
    methods: {
        showMorePopularMixes() {
            this.visiblePopularMixes *= 2
        },
        showMoreLatestMixes() {
            DataLayer.mixes
                .getLatestByGenre(this.genreTitle, this.INITIAL_LATEST_MIXES, this.visibleLatestMixes)
                .then(response => {
                    this.latestMixes.push(...response.body)
                    if (response.body.length === 0) {
                        this.INITIAL_LATEST_MIXES -= 1
                    }
                })
            this.visibleLatestMixes += this.INITIAL_LATEST_MIXES
        },
    },
}
</script>

<template>
    <Layout>
        <BaseHero
            :text="genreTitle"
            description="Genre"
            :resource="genre"
            resource-type="genre"
            class="hero"
        />
        <BaseContent>
            <BaseSection
                v-if="popularMixes && popularMixes.length > 0"
                title="Popular Mixes"
                short
                padded
            >
                <MixTableBlock
                    :mixes="popularMixes"
                    :buttons="{ playCount: true }"
                    genres
                    is-indexed-list
                />
                <BaseLoadMore
                    :id="genreTitle"
                    :list="popularMixes"
                    :visible="5"
                    :more="5"
                    :max="10"
                    :get-fc="showMorePopularMixes"
                    :update-fc="() => {}"
                />
            </BaseSection>
            <BaseSection
                v-if="latestMixes && latestMixes.length > 0"
                title="All Mixes"
                padded
            >
                <MixGridBlock :mixes="latestMixes" />
                <BaseLoadMore
                    :id="genreTitle"
                    :list="latestMixes"
                    :visible="12"
                    :more="12"
                    :get-fc="showMoreLatestMixes"
                    :update-fc="() => {}"
                />
            </BaseSection>
            <BaseSection
                v-if="artists && artists.length > 0"
                title="DJs"
            >
                <ArtistBlock
                    :artists="artists"
                    wrapping
                    padded-left-mobile
                />
            </BaseSection>
        </BaseContent>
    </Layout>
</template>

<style lang="scss" scoped>
@import '@design';

.hero {
    margin-bottom: $s-xxl;
}
</style>
