import DataLayer from '@services/data-layer/index'
import getAndUpdate from '@utils/get-and-update'
import { getCookie, deleteCookie } from '@utils/cookies'
import localSave from '@utils/local-save'
import analyticsService from './analytics-service'
import settingsService from './settings-service'

const CHECK_VERSION_INTERVAL_DURATION = 1000 * 60 * 60 // 1 hour

class AppInitializer {
    currentUser = null

    constructor(store, router, dataLayer, messages) {
        this.store = store
        this.router = router
        this.dataLayer = dataLayer
        this.messages = messages
        this.checkVersionInterval = null
        this.currentUser = this.store.state.auth.currentUser?.members_id
    }

    async initialize() {
        await this.checkAuthentication()
        this.initChannels()
        this.startCodeVersionCheck()
        await this.initSettings()
        await this.initPremium()
        const onboarding = await this.initOnboarding()
        if (!onboarding) await this.handlePremiumOnboarding()
    }

    // Authentication Methods
    async checkAuthentication() {
        const oauthSession = getCookie('oauthSession')
        if (!oauthSession) return

        const user = JSON.parse(oauthSession)
        await this.store.dispatch('auth/setUser', user)
        await this.store.dispatch('member/init', null, { root: true })
        deleteCookie({ name: 'oauthSession', domain: process.env.VUE_APP_COOKIES_DOMAIN })

        const redirectURL = localSave.get('redirectURL') || '/'
        if (redirectURL !== '/')  window.location.replace(redirectURL)
    }

    // Channel Initialization
    initChannels() {
        getAndUpdate(
            channels => this.store.commit('channels/SET_CHANNELS', channels),
            this.dataLayer.channels.get()
        )
    }

    // Code Version Checking
    startCodeVersionCheck() {
        this.checkCodeVersion()
        this.checkVersionInterval = setInterval(() => {
            this.checkCodeVersion()
        }, CHECK_VERSION_INTERVAL_DURATION)
    }

    async checkCodeVersion() {
        const localCodeVersion = localSave.get('localCodeVersion')
        try {
            const response = await DataLayer.versions.get()
            const actualCodeVersion = response.body.clientVersion
            localSave.save('actualCodeVersion', actualCodeVersion)

            if (!localCodeVersion)  localSave.save('localCodeVersion', actualCodeVersion)
            else if (localCodeVersion !== actualCodeVersion) {
                this.notifyForNewVersion(localCodeVersion, response.body)
            }
        } catch (error) {
            console.error('Failed to check code version:', error)
        }
    }

    notifyForNewVersion(oldVersion, newVersion) {
        const messageKey = newVersion.severity === 'critical'
            ? 'CRITICAL_UPDATE_AVAILABLE'
            : 'UPDATE_AVAILABLE'

        this.store.dispatch('appearance/handleNotification', {
            message: this.messages(messageKey),
            type: 'warning',
            hasReload: true,
            isPersistentNotification: true,
            forceUpdate: newVersion.severity === 'critical',
        })
    }

    // Premium Initialization
    async initPremium() {
        if (!this.store.getters['auth/loggedIn']) return

        const premiumService = DataLayer.subscriptions
        const currentUserId = this.currentUser?.id
        const promises = [premiumService.getProducts()]

        if (currentUserId) promises.push(premiumService.getSubscriptionsByMemberId(currentUserId))

        const [plans, subscriptions] = await Promise.all(promises)
        this.store.commit('premium/SET_PLANS', plans.body)

        if (subscriptions) {
            this.store.commit('premium/SET_SUBSCRIPTIONS', subscriptions.body)
            await this.handleActiveSubscription()
        }
    }

    async handleActiveSubscription() {
        const activeSubscription = this.store.getters['premium/activeSubscription']
        if (!activeSubscription) return

        const transaction = await DataLayer.subscriptions.getCheckoutById(
            activeSubscription.subscriptionsCheckoutId.id
        )
        this.store.commit('premium/SET_ACTIVE_TRANSACTION', transaction.body)
    }

    async handlePremiumOnboarding() {
        const isLoggedIn = this.store.getters['auth/loggedIn']
        const isPremium = this.store.getters['premium/isPremium']

        if (!isLoggedIn || localStorage.getItem('hasSkippedPremiumOnboarding') || isPremium) return

        if (!this.router.currentRoute.name.includes('premium')) {
            analyticsService.premiumUpsellInitiated('non-premium-log-in')
            await this.router.push({ path: '/premium' })
        }
    }

    // Settings Initialization
    async initSettings() {
        const isLoggedIn = this.store.getters['auth/loggedIn']
        const isPremium = this.store.getters['premium/isPremium']

        if (!isLoggedIn) return

        const onDemandQuality = isPremium ? 'high' : 'low'
        await settingsService.setCurrentOnDemandQuality(onDemandQuality)
        this.store.commit('settings/SET_SETTINGS', { currentOnDemandQualityKey: onDemandQuality })
    }

    // Favorites onboarding
    async initFavorites() {
        if(!this.currentUser) return

        const favoritesService = DataLayer.favorites
        const currentUserId = this.currentUser.id

        const [artists, genres] = await Promise.all([
            favoritesService.getAll({type: 'artists', membersId: currentUserId }),
            favoritesService.getAll({type: 'genres', membersId: currentUserId }),
        ])

        return {
            artists,
            genres
        }
    }

    async initOnboarding() {
        if(!this.currentUser) return false

        const MINIMUM_COUNT = 3
        const ONBOARDING_PATH = 'onboarding'
        const { artists, genres } = await this.initFavorites()
        const shouldOnboard = artists.body.length < MINIMUM_COUNT && genres.body.length < MINIMUM_COUNT
        const isOnboarding = this.router.currentRoute.name === ONBOARDING_PATH

        if(!shouldOnboard) return false

        else {
            if(isOnboarding) return true
            await this.router.push({ name: ONBOARDING_PATH })
            return true
        }
    }

    // Cleanup
    destroy() {
        if (this.checkVersionInterval) clearInterval(this.checkVersionInterval)
    }
}

export default AppInitializer
