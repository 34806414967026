export function uniqueFromArray(array = [], property = null) {
    if (property) {
        return array.filter((el, index) => array.findIndex(elInner => elInner[property] === el[property]) === index)
    }
    return array.filter((el, index) => array.indexOf(el) === index)
}

export function combineArrays(arrays) {
    return arrays.reduce((acc, val) => acc.concat(val), [])
}

export function uniqueFromCombinedArrays(arrays, property) {
    return uniqueFromArray(combineArrays(arrays), property)
}
