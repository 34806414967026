export const state = {
    mixesList: [],
    artistsList: [],
    showsList: [],
    genresList: []
}

export const getters = {}

export const mutations = {
    UPDATE_FAVORITE_MIXES(state, data) {
        if (!data) return
        state.mixesList = data
    },
    UPDATE_FAVORITE_ARTISTS(state, data) {
        if (!data) return
        state.artistsList = data
    },
    UPDATE_FAVORITE_SHOWS(state, data) {
        if (!data) return
        state.showsList = data
    },
    UPDATE_FAVORITE_GENRES(state, data) {
        if (!data) return
        state.genresList = data
    }
}

export const actions = {}
