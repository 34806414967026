import Api from './../api'
import DataLayer from './../data-layer/index'

DataLayer.$extend('favorites', {
    async getAll({ type, membersId, offset, limit, order }) {
        if (!['mixes', 'artists', 'shows', 'genres'].includes(type)) {
            throw new Error('Invalid type')
        }

        const items = await Api.favorites.get({ type, membersId, offset, limit, order })

        return items

    },
    add({ type, membersId, id }) {
        const payload = {
            members_id: {
                id: membersId,
            },
            [`${type}_id`]: {
                id,
            }
        }
        return Api.favorites.post({ type, membersId, id }, payload)
    },
    remove({ type, membersId, id }) {
        return Api.favorites.delete({ type, membersId, id })
    },
})
