<script>
import FullscreenLayout from './../../../layouts/fullscreen.vue'

export default {
    name: 'PickerLayout',
    components:{FullscreenLayout},
    props: {
        isLoading: {
            type: Boolean,
            required: true,
        },
        hasResults: {
            type: Boolean,
            required: true,
        },
    },
}
</script>

<template>
    <FullscreenLayout>
        <BaseContent class="layout-container">
            <div class="picker-header-container">
                <slot name="header"/>
            </div>
            <div>
                <div class="picker-container">
                    <div>
                        <BaseSpinner
                            v-if="isLoading"
                            size="large"
                            class="loader"
                        />
                        <div
                            v-if="!isLoading && hasResults"
                            class="picker-items"
                        >
                            <slot name="picker"/>
                        </div>
                        <h5
                            v-if="!isLoading && !hasResults"
                            class="results-error"
                        >No results.</h5>
                    </div>
                </div>
            </div>
            <div class="actions-container">
                <div
                    padded
                    class="actions-content"
                >
                    <slot name="actions"/>
                </div>
            </div>
        </BaseContent>
    </FullscreenLayout>
</template>

<style lang="scss" scoped>
@use '@design' as *;
@use 'sass:color';

.layout-container {
    max-width: 100%;
}

.picker-header-container {
    // position: sticky;
    // top: 0;
    // z-index: $layer-page-z-index;
}

.picker-container {
    // height: 80vh;
    // overflow: auto;
    padding-top: $s-m;
}

.picker-items {
    padding-bottom: $s-h * 5;
}

.results-error {
    text-align: center;
}

.actions-container {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0 auto;
    padding: $s-xl $s-xxl 0;
    background-color: color.scale($c-dusty-gray, $alpha: -90%);
    backdrop-filter: blur($s-s);
    border-top: 1px solid color.scale($c-dusty-gray, $alpha: -90%);

    .actions-content {
        margin: 0 auto;
    }
}
</style>
