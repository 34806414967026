<script>
import { mapGetters } from 'vuex'
import Logo from '@components/logo'
import analyticsService from '../services/analytics-service'

export default {
    components: {
        Logo,
    },
    computed: {
        ...mapGetters('auth', ['loggedIn']),
        ...mapGetters('member', ['email', 'premium', 'superAdmin']),
        isPremium() {
            return this.$store.getters['premium/isPremium']
        },
        entitledFreeTrial() {
            return !this.$store.getters['premium/hasEverBeenPremium']
        },
        isMenuOpen() {
            return this.$store.state.appearance.isMainMenuOpen
        },
        env() {
            return process.env.VUE_APP_ENV
        },
    },
    watch: {
        $route() {
            this.closeMainMenu()
        },
    },
    mounted(){
        addEventListener('resize', this.closeMainMenu, { passive: true })
    },
    beforeDestroy(){
        removeEventListener('resize', this.closeMainMenu)
    },
    methods: {
        onPremiumUpsellClick() {
            analyticsService.premiumUpsellInitiated('sidebar')
        },
        closeMainMenu() {
            this.$store.dispatch('appearance/closeMainMenu')
        },
    },
}
</script>

<template>
    <div
        :is-nav-open="isMenuOpen"
        :class="[$style.sideBar, { [$style.activeNav]: isMenuOpen }]"
    >
        <Logo :class="$style.logo" />
        <BaseLink
            v-if="loggedIn && !isPremium"
            name="premium/view-plans"
            :class="$style.premiumLink"
        >
            <BaseButton
                button-type="highlight"
                size="xsmall"
                :modifiers="{ fill: true, noMargins: true }"
                @click="onPremiumUpsellClick"
            >{{ entitledFreeTrial ? 'START FREE PREMIUM TRIAL' : 'UPGRADE TO PREMIUM' }}</BaseButton>
        </BaseLink>
        <div :class="$style.section">
            <BaseLink
                :class="[$style.title, $style.baseLink]"
                :to="{ name: 'home' }"
                name="home"
            >
                <BaseIcon
                    :class="[$style.icon, $style.menuIcon, $style.iconHome]"
                    name="home"
                />
                <span :class="$style.header">Home</span>
            </BaseLink>
            <BaseLink
                :class="[$style.item, $style.baseLink, { [$style.active]: $route.name === 'browse/charts/index' }]"
                :to="{ name: 'browse/charts/index' }"
                name="browse/charts/index"
            >
                <div :class="$style.link">Charts</div>
            </BaseLink>
            <BaseLink
                :class="[$style.item, $style.baseLink, { [$style.active]: $route.name === 'browse/playlists/index' }]"
                :to="{ name: 'browse/playlists/index' }"
            >
                <div :class="$style.link">Playlists</div>
            </BaseLink>
            <BaseLink
                :class="[$style.item, $style.baseLink, { [$style.active]: $route.name === 'browse/genres' }]"
                :to="{ name: 'browse/genres' }"
            >
                <div :class="$style.link">Genres & Moods</div>
            </BaseLink>
            <BaseLink
                :class="[
                    $style.item,
                    $style.baseLink,
                    {
                        [$style.active]:
                            $route.name === 'browse/mixes/latest' || $route.name === 'browse/mixes/trending',
                    },
                ]"
                :to="{ name: 'browse/mixes/latest' }"
            >
                <div :class="$style.link">Mixes</div>
            </BaseLink>
        </div>
        <div :class="[$style.section, $style.sectionSearch]">
            <BaseLink
                :class="[$style.title, $style.baseLink]"
                :to="{ name: 'search' }"
            >
                <BaseIcon
                    :class="[$style.icon, $style.menuIcon]"
                    name="search"
                />
                <span :class="$style.header">Search</span>
            </BaseLink>
        </div>
        <div :class="$style.section">
            <BaseLink
                :class="[$style.title, $style.baseLink]"
                :to="{ name: 'channel-index' }"
                name="radio"
            >
                <BaseIcon
                    :class="[$style.icon, $style.menuIcon]"
                    name="radio"
                />
                <span :class="$style.header">Radio</span>
            </BaseLink>
            <BaseLink
                v-for="channelLink in $store.state.channels.channels"
                :key="channelLink.id"
                :class="[$style.item, $style.baseLink, { [$style.active]: channelLink.id === $route.params.channelId }]"
                :to="{ name: 'channel', params: { channelId: channelLink.id } }"
                name="channel"
            >
                <div :class="$style.link">
                    {{ channelLink.title }}
                </div>
            </BaseLink>
        </div>
        <div :class="$style.section">
            <div
                :class="[$style.title, $style.baseLink]"
                name="radio"
            >
                <BaseIcon
                    :class="[$style.icon, $style.menuIcon]"
                    name="browse"
                />
                <span :class="$style.header">Your Music</span>
            </div>
            <BaseLink
                :class="[$style.item, $style.baseLink, { [$style.active]: $route.name === 'your-music/mixes' }]"
                :to="{ name: 'your-music/mixes' }"
                name="channel"
            >
                <div :class="$style.link">Mixes</div>
            </BaseLink>
            <BaseLink
                :class="[$style.item, $style.baseLink, { [$style.active]: $route.name === 'your-music/artists' }]"
                :to="{ name: 'your-music/artists' }"
                name="channel"
            >
                <div :class="$style.link">Artists</div>
            </BaseLink>
            <BaseLink
                :class="[$style.item, $style.baseLink, { [$style.active]: $route.name === 'your-music/shows' }]"
                :to="{ name: 'your-music/shows' }"
                name="channel"
            >
                <div :class="$style.link">Shows</div>
            </BaseLink>
            <BaseLink
                v-if="env === 'development'"
                :class="[$style.item, $style.baseLink, { [$style.active]: $route.name === 'your-music/genres' }]"
                :to="{ name: 'your-music/genres' }"
                name="channel"
            >
                <div :class="$style.link">Genres (dev only)</div>
            </BaseLink>
        </div>
        <div :class="[$style.section, $style.sectionAccount]">
            <BaseLink
                v-if="!loggedIn"
                :class="[$style.item, $style.baseLink]"
                name="login"
            >
                <div :class="$style.link">Log in</div>
            </BaseLink>
            <div v-else>
                <BaseButton
                    button-type="highlight"
                    size="xsmall"
                    :modifiers="{ fill: true, noMargins: true }"
                    @click="() => {}"
                >{{ email }}</BaseButton>
                <br>
                <BaseLink
                    :class="[$style.title, $style.baseLink, { [$style.active] : $route.name === 'account/overview' }]"
                    name="account/overview"
                >
                    <span :class="$style.header">Account Management</span>
                </BaseLink>
                <BaseLink
                    :class="[$style.title, $style.baseLink, { [$style.active] : $route.name === 'settings' }]"
                    name="settings"
                >
                    <span :class="$style.header">Settings</span>
                </BaseLink>
                <BaseLink
                    :class="[$style.item, $style.baseLink]"
                    name="logout"
                >
                    <div :class="$style.link">Log out</div>
                </BaseLink>
            </div>
        </div>
    </div>
</template>

<style lang="scss" module>
@use 'sass:math';
@use '@design' as *;

.menuButton {
    position: absolute;
    top: math.div($size-menu-bar-height, 2);
    left: 30px;
    cursor: pointer;
    transform: translateY(-50%);
}

.icon {
    width: 24px;
    height: 24px;
    margin-right: $s-xxs;
    vertical-align: middle;
    fill: $c-white;

    &Home {
        width: 20px;
        height: 20px;
    }
}

.menuIcon {
    display: none;
}

.header {
    vertical-align: middle;
}

.sideBar {
    position: fixed;
    top: $size-menu-bar-height;
    left: 0;
    z-index: $layer-modal-z-index;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: $size-side-bar;
    height: calc(100% - #{$size-music-player + $size-menu-bar-height});
    padding: $s-xxl $s-xxl $s-h;
    overflow-y: auto;
    background: $c-grey-heavy-metal;
    transition: transform 0.3s ease-in-out;
    transform: translateX(-100%);

    &.activeNav {
        transform: translateX(0);
    }

    &::-webkit-scrollbar-thumb {
        background-color: transparentize($c-tuna, 0.8);
    }
}

.section {
    margin-top: $s-l;

    &Search {
        display: none;
    }
}

.logo {
    display: none;
}

.title {
    display: block;
    margin-bottom: 15px;
    font-size: 14px;
    font-weight: 900;
    line-height: 18px;
    letter-spacing: -0.5px;

    &, &:visited {
        color: $c-white;
    }
}

.link {
    display: block;
    font-size: 13px;
    font-weight: 500;
    line-height: 36px;
    color: $c-text-faded;
    text-decoration: none;
    letter-spacing: -0.09px;
    vertical-align: middle;

    &:hover {
        color: $c-pink-mandy;
    }
}

.baseLink {

    &:hover, &:active {
        text-decoration: none;
    }
}

.item {
    display: flex;
    flex: 1 1 auto;

    &.active {
        .link {
            font-weight: bold;
            color: $c-white;
        }
    }

    &:last-of-type {
        margin-bottom: $s-m;
    }
}

.textEmail {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@include desktop {
    .premiumLink {
        display: none;
    }

    .sideBar {
        position: sticky;
        top: 0;
        z-index: auto;
        width: $size-large-side-bar;
        height: calc(100vh - #{$size-music-player});
        padding-bottom: $s-xxl;
        transform: translateX(0);
    }

    .menuButton {
        display: none;
    }

    .menuIcon {
        display: unset;
    }

    .logo {
        display: block;
        margin-bottom: $s-xl;
    }

    .item {
        &:last-of-type {
            margin-bottom: 0;
        }
    }

    .section {

        &Search {
            display: block;
        }

        &Account {
            display: none;
        }
    }
}
</style>
