<script>
import ArtistOptions from '@components/artist/artist-options'
export default {
    components: {
        ArtistOptions,
    },
    props: {
        artist: {
            type: Object,
            default: () => {},
        },
        hasTypeTitle: {
            type: Boolean,
            default: false,
        },
        isOnSelectMode: {
            type: Boolean,
            default: false
        },
        isSelected: {
            type: Boolean,
            default: false
        }
    },
}
</script>

<template>
    <div class="tile" >
        <BaseLink
            class="link"
            :to="{ path: isOnSelectMode ? '' : `/artist/${artist.url}/${artist.id}` }"
        >
            <div>
                <div class="inner">
                    <div
                        class="image-container"
                        :class="{ 'is-selected': isSelected }"
                    >
                        <BaseResourceImage
                            :resource="artist"
                            class="image"
                            :width="150"
                            :height="150"
                            type="photo"
                        />
                    </div>
                    <div
                        v-if="!isOnSelectMode"
                        class="options"
                        @click.prevent="() => {}"
                    >
                        <BaseDropdown :padded="false">
                            <BaseButton
                                slot="trigger"
                                :modifiers="{ autoWidth: true, transparent: true, dots: true }"
                                aria-label="Options"
                                button-type="tag"
                                size="small"
                            />
                            <div slot="dropdown">
                                <ArtistOptions :artist="artist" />
                            </div>
                        </BaseDropdown>
                    </div>
                </div>
                <BasePlaceholder
                    class="title"
                    :text="artist.title"
                    type="artist"
                    tag="div"
                />
                <div
                    v-if="hasTypeTitle"
                    class="type-title"
                >Artist</div>
            </div>
        </BaseLink>
    </div>
</template>

<style lang="scss" scoped>
@use 'sass:math';
@use '@design' as *;


.link {
    display: block;
}

.inner {
    position: relative;
}

.image-container {
    border: 2px solid transparent;
    border-radius: 50%;

    &.is-selected {
        border-color: $c-highlight;
        box-shadow: 0 0 $s-m 0 $c-highlight;
    }
}

.image {
    position: relative;
    overflow: hidden;
    background: $c-classics-channel-grad;
    border-radius: 50%;

    &::after {
        @include fill;

        content: '';
        background: $c-purple-red-gradient;
        border-radius: 50%;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
    }
}

.options {
    @include center;

    z-index: $layer-dropdown-z-index;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.title {
    @extend %contentTitleM;

    margin-top: $size-grid-padding;
}

.tile {
    @include square(160px);

    max-width: none;
    padding: $size-grid-padding;
    margin: 0 0 $s-xl;
    text-align: center;

    &:hover {
        .image::after {
            opacity: 0.5;
        }

        .options {
            pointer-events: visible;
            opacity: 1;
        }
    }
}

.type-title {
    @extend %contentTitleM;

    margin-top: $s-s;
    text-align: center;
}

@include desktop {
    .title {
        margin-top: $size-large-grid-padding;
    }
}
</style>
